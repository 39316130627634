header {
	position: relative;
}

.logo {
	display: flex;
	margin: 2px 0;
	padding: 5px;
	align-items: center;
	justify-content: left;
	background-color: #C93375;
}

.navbar ul {
	padding: 0;
	margin: 0;
}

.navbar {
	padding: 0 10px;
	text-align: right;
	box-shadow: 0 2px 10px -5px #cdcdcd;
}

.navbar ul li {
	/* list-style-type: none; */
	display: inline-block;
	margin-left: 30px;
}

.navbar ul li a {
	border: 0;
	cursor: pointer;
	font-size: 15px;
	padding: 10px 0;
	color: #000000;
	font-weight: 400;
	background: none;
	display: inline-block;
	text-decoration: none;
	transition: all 0.4s ease;
	border-bottom: 1px solid transparent;
}

.navbar ul li a.active {
	color: #C93375;
	border-bottom: 1px solid #C93375;
}

.navbar ul li:first-child {
	margin-left: 5px;
}