.main {
	padding-top: 40px;
}

.heading {
	font-size: 16px;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.616);
}

.logo {
	text-align: center;
}

.logo img {
	width: 60%;
	max-width: 120px;
}

.card {
	padding: 2rem;
	margin: 2rem auto;
	min-height: 300px;
	position: relative;
	box-shadow: 0 0 50px -10px #cdcdcd;
}

.order-id {
	right: 0;
	top: 15px;
	padding: 12px 20px;
	position: absolute;
	display: inline-block;
	border: 1px solid #cdcdcd;
	background-color: #cdcdcd;
}

.para {
	font-size: 12px;
}

.upper {
	color: rgba(0, 128, 0, 0.603);
	text-align: right;
	font-size: 15px;
	font-weight: 700;
	font-family: monospace;
}

.bottom {
	font-size: 14px;
	font-weight: 700;
	text-align: right;
}

.time_heading {
	font-size: 18px;
	margin-top: 16px;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.836);
}

.today_box {
	margin: 5px;
	display: flex;
	cursor: pointer;
	color: #6a6a6a;
	padding: 4px 20px;
	align-items: center;
	justify-content: space-between;
	border: 1px solid rgba(128, 128, 128, 0.37);
}

.date_box {
	padding: 0 10px;
	line-height: 25px;
}

.today_box svg {
	font-size: 18px;
}

.calender {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 15px;
	justify-content: flex-start;
}

.time_slots {
	gap: 10px;
	display: flex;
	margin-top: 10px;
	flex-direction: column;
}

.time_selection {
	cursor: pointer;
	padding: 11px 0;
	font-weight: 700;
	text-align: center;
	transition: all 0.6s ease;
	border: 1px solid rgba(128, 128, 128, 0.404);
}

.time_selection.active {
	box-shadow: 0 0 60px -20px #6a6a6a;
}

.back {
	color: #ea353c;
	cursor: pointer;
	font-size: 14px;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 16px;
	text-decoration: underline;
}

.MuiCalendarPicker-root {
	width: 100% !important;
}

.MuiCalendarPicker-root .css-1dozdou {
	padding: 0;
}

.MuiTypography-root {
	width: 100%;
}

.MuiCalendarPicker-root div[role="row"],
.MuiCalendarPicker-root div[role="cell"] {
	width: 100%;
	text-align: center;
}

.delivery-done {
	margin: 30px 0;
	text-align: center;
}

.delivery-image {
	margin: auto;
	max-width: 150px;
}

.delivery-image img {
	width: 100%;
}

@media only screen and (max-width: 600px) {
	.order-id {
		top: -10px;
		padding: 8px 16px;
	}

	.main {
		padding-top: 24px;
	}

	.card {
		padding: 3rem 16px;
	}
}
