@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
	background-color: #f8f8fc !important;
	font-family: "Inter", sans-serif;
}

.app-modal-body::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.app-modal-body::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: white;
	box-shadow: inset 0 0 5px #fff;
}

/* Handle */
.app-modal-body::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: gray;
	border: 1px solid white;
}

.app-modal-body::-webkit-scrollbar-thumb:hover {
	background: #707070;
}

.sentry-error-embed-wrapper {
	z-index: 9999 !important;
}

.app-container {
	padding: 16px;
	background: white;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px #f7f7f7 inset !important;
	-webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
}

.goback a {
	color: #1e2731;
	display: flex;
	align-items: center;
	padding-top: 25px;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.3s ease;
}

.form {
	position: relative;
	padding: 25px;
	margin-bottom: 16px;
	border-radius: 16px;
	background-color: #ffffff;
}

.code-tags {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.code-tags .code-tag-item {
	font-size: 16px;
	padding: 2px 10px;
	margin: 6px 6px 6px 0;
	border-radius: 25px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	border: 1px solid #cdcdcd;
}

.MuiModal-root h3 {
	margin-top: 0;
}

.MuiCheckbox-root.Mui-disabled {
	color: rgb(0 0 0 / 70%);
}

.holiday-tag ul {
	margin: 0;
	padding: 0;
}

.holiday-tag ul li {
	cursor: pointer;
	padding: 8px 12px;
	position: relative;
	margin-right: 16px;
	margin-bottom: 12px;
	display: inline-block;
	box-shadow: 0 0 40px -10px #cdcdcd;
}

.holiday-tag ul li p {
	margin: 0;
}

.holiday-tag ul li svg {
	top: -10px;
	fill: red;
	right: -10px;
	position: absolute;
}